export enum ROUTES {
  LOGIN = 'bienvenido',
  SOLICIT = 'solicitud',
  SIMULATOR = 'simulador',
  AMOUNT_CONFIRMATION = 'confirmacion-monto',
  INVALID_COMPANY = 'empresa-no-asociada',
  INVALID_USER = 'solicitud-no-disponible',
  UNAVAILABLE_PRODUCT = 'producto-no-disponible',
  MAXIMUM_PERCENTAGE_REACHED = 'monto-superado',
  AMOUNT_NOT_AVAILABLE = 'monto-no-disponible',
  ACTIVE_SALARY_ADVANCE = 'adelanto-activo',
  ACTIVE_AT_WORK_REQUEST = 'solicitud-activa-en-proceso',
}

export enum StepRoutes {
  PERSONAL_INFO = '/sobre-ti',
  ADDRESS = '/domicilio',
  IDENTITY = '/identificacion',
  DOCUMENTS = '/documentacion',
  PROCESSING_REQUEST = '/finalizado',
}
