import { ROUTES } from './routes';

const imgUrl = `${process.env.PUBLIC_URL}/public/img`;

export const ArrayImg = {
  [ROUTES.LOGIN]: {
    desktop: `${imgUrl}/login/desktop-img.png`,
    mobile: `${imgUrl}/login/mobile-img.png`,
    alt: 'Imagen de registro',
  },
  [ROUTES.INVALID_COMPANY]: {
    desktop: `${imgUrl}/invalidCompany/desktop-img.png`,
    mobile: `${imgUrl}/invalidCompany/mobile-img.png`,
    alt: 'Imagen de compañia invalida',
  },
  [ROUTES.INVALID_USER]: {
    desktop: `${imgUrl}/invalidUser/desktop-img.png`,
    mobile: `${imgUrl}/invalidUser/mobile-img.png`,
    alt: 'Imagen de usuario invalido',
  },
  [ROUTES.MAXIMUM_PERCENTAGE_REACHED]: {
    desktop: `${imgUrl}/maxAmount/desktop-img.png`,
    mobile: `${imgUrl}/maxAmount/mobile-img.png`,
    alt: 'Imagen de monto superado',
  },
  [ROUTES.AMOUNT_NOT_AVAILABLE]: {
    desktop: `${imgUrl}/amountNotAvailable/desktop-img.png`,
    mobile: `${imgUrl}/amountNotAvailable/mobile-img.png`,
    alt: 'Imagen de monto no disponible',
  },
  [ROUTES.UNAVAILABLE_PRODUCT]: {
    desktop: `${imgUrl}/unavailableProduct/desktop-img.png`,
    mobile: `${imgUrl}/unavailableProduct/mobile-img.png`,
    alt: 'Imagen de producto no disponible',
  },
  [ROUTES.ACTIVE_SALARY_ADVANCE]: {
    desktop: `${imgUrl}/activeSalaryAdvance/desktop-img.jpg`,
    mobile: `${imgUrl}/activeSalaryAdvance/mobile-img.jpg`,
    alt: 'Imagen de adelanto activo',
  },
  [ROUTES.ACTIVE_AT_WORK_REQUEST]: {
    desktop: `${imgUrl}/activeAtWorkRequest/desktop-img.jpg`,
    mobile: `${imgUrl}/activeAtWorkRequest/mobile-img.jpg`,
    alt: 'Imagen de solicitud en proceso',
  },
};
