import { registerApplication, start } from 'single-spa';
import * as serviceWorker from './serviceWorker';
import Activity from './activityFns';
import { ArrayImg } from './imagesDictionary';
import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';

const registerApps = (): void => {
  registerApplication('mfHeader', () => import('./microfrontends/mfHeader/mfHeader.lifecycle'), Activity.mfHeader);
  registerApplication('mfStepper', () => import('./microfrontends/mfStepper/mfStepper.lifecycle'), Activity.mfStepper, {
    baseRoute: BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT,
    notAmount: BaseRoutes.SALARY_ADVANCE + ROUTES.SIMULATOR,
  });
  registerApplication('mfLogin', () => import('./microfrontends/mfLogin/mfLogin.lifecycle'), Activity.mfLogin);
  registerApplication(
    'mfInvalidUser',
    () => import('./microfrontends/mfInvalidUser/mfInvalidUser.lifecycle'),
    Activity.mfInvalidUser,
  );
  registerApplication(
    'mfUnavailableProduct',
    () => import('./microfrontends/mfUnavailableProduct/mfUnavailableProduct.lifecycle'),
    Activity.mfUnavailableProduct,
  );
  registerApplication(
    'mfMaximumPercentageReached',
    () => import('./microfrontends/mfMaximumPercentageReached/mfMaximumPercentageReached.lifecycle'),
    Activity.mfMaximumPercentageReached,
  );
  registerApplication(
    'mfAmountNotAvailable',
    () => import('./microfrontends/mfAmountNotAvailable/mfAmountNotAvailable.lifecycle'),
    Activity.mfAmountNotAvailable,
  );
  registerApplication(
    'mfInvalidCompany',
    () => import('./microfrontends/mfInvalidCompany/mfInvalidCompany.lifecycle'),
    Activity.mfInvalidCompany,
  );
  registerApplication('mfImage', () => import('./microfrontends/mfImage/mfImage.lifecycle'), Activity.mfImage, {
    images: ArrayImg,
  });
  registerApplication('mfFooter', () => import('./microfrontends/mfFooter/mfFooter.lifecycle'), Activity.mfFooter);
  registerApplication(
    'mfPersonalInfo',
    () => import('./microfrontends/mfPersonalInfo/mfPersonalInfo.lifecycle'),
    Activity.mfPersonalInfo,
  );
  registerApplication(
    'mfAddress',
    () => import('./microfrontends/mfAddressInfo/mfAddressInfo.lifecycle'),
    Activity.mfAddress,
  );
  registerApplication(
    'mfMatiValidation',
    () => import('./microfrontends/mfMatiValidation/mfMatiValidation.lifecycle'),
    Activity.mfMatiValidation,
  );
  registerApplication(
    'mfSimulation',
    () => import('./microfrontends/mfSimulation/mfSimulation.lifecycle'),
    Activity.mfSimulation,
  );
  registerApplication(
    'mfAmountConfirmation',
    () => import('./microfrontends/mfAmountConfirmation/mfAmountConfirmation.lifecycle'),
    Activity.mfAmountConfirmation,
  );
  registerApplication(
    'mfProcessingRequest',
    () => import('./microfrontends/mfProcessingRequest/mfProcessingRequest.lifecycle'),
    Activity.mfProcessingRequest,
  );
  registerApplication(
    'mfBankData',
    () => import('./microfrontends/mfBankData/mfBankData.lifecycle'),
    Activity.mfBankData,
  );
  registerApplication(
    'mfActiveSalaryAdvance',
    () => import('./microfrontends/mfActiveSalaryAdvance/mfActiveSalaryAdvance.lifecycle'),
    Activity.mfActiveSalaryAdvance,
  );
  registerApplication(
    'mfActiveAtWorkRequest',
    () => import('./microfrontends/mfActiveAtWorkRequest/mfActiveAtWorkRequest.lifecycle'),
    Activity.mfActiveAtWorkRequest,
  );
};

registerApplication('app', () => import('./microfrontends/app.lifecycle'), Activity.app);
start();
registerApps();

serviceWorker.unregister();
