import { BaseRoutes } from './baseRoutes';
import navigateToUrl from './microfrontends/shared/utilities/navigateToUrl';
import { ROUTES, StepRoutes } from './routes';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some((prefix) => location.pathname === prefix || location.pathname === `${prefix}/`);

const app = (): boolean => {
  if (
    location.pathname === BaseRoutes.SALARY_ADVANCE ||
    location.pathname === BaseRoutes.SALARY_ADVANCE.slice(0, BaseRoutes.SALARY_ADVANCE.length - 1) ||
    location.pathname === '/'
  )
    navigateToUrl(`${BaseRoutes.SALARY_ADVANCE + ROUTES.LOGIN}` || '');
  return true;
};

const mfHeader = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.SALARY_ADVANCE + ROUTES.LOGIN,
    BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_USER,
    BaseRoutes.SALARY_ADVANCE + ROUTES.MAXIMUM_PERCENTAGE_REACHED,
    BaseRoutes.SALARY_ADVANCE + ROUTES.UNAVAILABLE_PRODUCT,
    BaseRoutes.SALARY_ADVANCE + ROUTES.AMOUNT_NOT_AVAILABLE,
    BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_COMPANY,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SIMULATOR,
    BaseRoutes.SALARY_ADVANCE + ROUTES.AMOUNT_CONFIRMATION,
    BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_SALARY_ADVANCE,
    BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_AT_WORK_REQUEST,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.PERSONAL_INFO,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.ADDRESS,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.IDENTITY,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.PROCESSING_REQUEST,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.DOCUMENTS,
  );
const mfStepper = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.PERSONAL_INFO,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.ADDRESS,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.IDENTITY,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.DOCUMENTS,
  );
const mfFooter = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.SALARY_ADVANCE + ROUTES.LOGIN,
    BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_USER,
    BaseRoutes.SALARY_ADVANCE + ROUTES.MAXIMUM_PERCENTAGE_REACHED,
    BaseRoutes.SALARY_ADVANCE + ROUTES.UNAVAILABLE_PRODUCT,
    BaseRoutes.SALARY_ADVANCE + ROUTES.AMOUNT_NOT_AVAILABLE,
    BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_COMPANY,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SIMULATOR,
    BaseRoutes.SALARY_ADVANCE + ROUTES.AMOUNT_CONFIRMATION,
    BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_SALARY_ADVANCE,
    BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_AT_WORK_REQUEST,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.PERSONAL_INFO,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.ADDRESS,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.IDENTITY,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.PROCESSING_REQUEST,
    BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.DOCUMENTS,
  );
const mfLogin = (location: Location): boolean => prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.LOGIN);
const mfInvalidUser = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_USER);
const mfUnavailableProduct = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.UNAVAILABLE_PRODUCT);
const mfMaximumPercentageReached = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.MAXIMUM_PERCENTAGE_REACHED);
const mfAmountNotAvailable = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.AMOUNT_NOT_AVAILABLE);
const mfInvalidCompany = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_COMPANY);
const mfActiveSalaryAdvance = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_SALARY_ADVANCE);
const mfActiveAtWorkRequest = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_AT_WORK_REQUEST);
const mfImage = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.SALARY_ADVANCE + ROUTES.LOGIN,
    BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_USER,
    BaseRoutes.SALARY_ADVANCE + ROUTES.MAXIMUM_PERCENTAGE_REACHED,
    BaseRoutes.SALARY_ADVANCE + ROUTES.UNAVAILABLE_PRODUCT,
    BaseRoutes.SALARY_ADVANCE + ROUTES.AMOUNT_NOT_AVAILABLE,
    BaseRoutes.SALARY_ADVANCE + ROUTES.INVALID_COMPANY,
    BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_SALARY_ADVANCE,
    BaseRoutes.SALARY_ADVANCE + ROUTES.ACTIVE_AT_WORK_REQUEST,
  );
const mfPersonalInfo = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.PERSONAL_INFO);
const mfAddress = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.ADDRESS);
const mfMatiValidation = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.IDENTITY);
const mfSimulation = (location: Location): boolean => prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.SIMULATOR);
const mfAmountConfirmation = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.AMOUNT_CONFIRMATION);
const mfProcessingRequest = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.PROCESSING_REQUEST);
const mfBankData = (location: Location): boolean =>
  prefix(location, BaseRoutes.SALARY_ADVANCE + ROUTES.SOLICIT + StepRoutes.DOCUMENTS);

export default {
  app,
  mfHeader,
  mfStepper,
  mfLogin,
  mfInvalidUser,
  mfMaximumPercentageReached,
  mfUnavailableProduct,
  mfAmountNotAvailable,
  mfInvalidCompany,
  mfImage,
  mfFooter,
  mfPersonalInfo,
  mfAddress,
  mfMatiValidation,
  mfSimulation,
  mfAmountConfirmation,
  mfProcessingRequest,
  mfBankData,
  mfActiveSalaryAdvance,
  mfActiveAtWorkRequest,
};
